/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default function useAnalysisTypeTranslation(analysisType: AnalysisType) {
  const analysisTypes: Record<AnalysisType, string> = {
    [AnalysisType.BANK]: t('data_requests.analysis_type.bank'),
    [AnalysisType.BANK_GENERAL_LEDGER]: t('data_requests.analysis_type.bank'),
    [AnalysisType.ACCOUNTS_PAYABLE]: t('data_requests.analysis_type.accounts_payable'),
    [AnalysisType.ACCOUNTS_RECEIVABLE]: t('data_requests.analysis_type.accounts_receivable'),
    [AnalysisType.GENERAL_LEDGER]: t('data_requests.analysis_type.general_ledger'),
    [AnalysisType.GENERIC]: t('data_requests.analysis_type.generic'),
    [AnalysisType.PERSONNEL]: t('data_requests.analysis_type.personnel'),
  }

  return analysisTypes[analysisType]
}
